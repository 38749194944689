 import { Component, h, State, Element, Prop, Watch, Listen } from '@stencil/core';
 import { FH_API_KEY } from '../../global/global';

 @Component ({
    tag: 'mw-stock-price',
    styleUrl: 'stock-price.css',
    shadow: true
 })
 export class StockPrice {
    stockInput: HTMLInputElement;
    //initialStockSymbol: string;

    @Element() el: HTMLElement;
    
    @State() fetchedPrice: number;
    @State() stockUserInput: string;
    @State() stockInputValid = false;
    @State() error: string;
    @State() loading = false;

    @Prop({ mutable: true }) fetchedDif: string;
    @Prop({ mutable: true, reflect: true}) stockSymbol: string;

    @Watch('stockSymbol')
    stockSymbolChanged(newVal: string, oldVal: string){
        if(newVal != oldVal){
            this.fetchStockPrice(newVal);
            this.stockUserInput = newVal;
            this.fetchStockPrice(newVal);

        }
    }

    onUserInput(e:Event){
        this.stockUserInput = (e.target as HTMLInputElement).value;
        if(this.stockUserInput.trim() !== '') {
            this.stockInputValid = true;
        }else{
            this.stockInputValid = false;
        }
    }

    onFetchStockPrice(e: Event){
        e.preventDefault();
        // const stockSymb = (this.el.shadowRoot.querySelector('#stock-symbol') as HTMLInputElement).value;
        //const stockSymb = this.stockInput.value;
        //fetch(`https://www.alphavantage.co/query?function=GLOBAL_QUOTE&symbol=${stockSymb}&apikey=${AV_API_KEY}`)
        this.stockSymbol = this.stockInput.value;
        this.fetchStockPrice(this.stockSymbol);
                
    }

    componentWillLoad(){
        // Just before render()
        // Can Change stateful properties just watch out for re-rendering weirdness
        console.log('componentWillLoad');
        if (this.stockSymbol){
            this.stockUserInput = this.stockSymbol;
            this.stockInputValid = true;
            this.fetchStockPrice(this.stockSymbol);
        }
    }
    componentDidLoad(){
       
    }
    componentWillUpdate(){
        // About to run re-render
        console.log('componentWillUpdate');
    }
    componentDidUpdate(){
        // Just ran re-render
        console.log('componentDidUpdate');
        // if(this.stockSymbol != this.initialStockSymbol){
        //     this.initialStockSymbol = this.stockSymbol;
        //     this.fetchStockPrice(this.stockSymbol);
        // }
    }
    disconnectedCallback(){
        // fires when component removed from dom. Good time for clean up of listeners, etc.
        console.log('disconnectedCallback');
    }

    @Listen('mwSymbolSelected', {target: 'body'})
    onStockSymbolSelected(event: CustomEvent) {
        if (event.detail && event.detail !== this.stockSymbol){
            this.stockSymbol = event.detail;
        }
    }

    fetchStockPrice(stockSymbol: string){
        this.loading = true;
        fetch(`https://finnhub.io/api/v1/quote?symbol=${stockSymbol}&token=${FH_API_KEY}`)
        .then(res => {
            if(res.status != 200){
                throw new Error("Fetch was denied.");
            }
            return res.json();
        }).then(parsedRes =>{
            if(parsedRes['c'] == 0){
                throw new Error("Invalid symbol!");
            }
            // if(!parsedRes['Global Quote']['05. price']){       
            //     throw new Error("Invalid symbol!");
            // }
            this.error = null;
            this.fetchedPrice = +parsedRes['c'];
            this.fetchedDif = parsedRes['pc'];
            this.loading = false;
        })
        .catch(err => {
            this.error = err.message;
            this.fetchedPrice = null;
            this.loading = false;
        });
    }

    hostData(){
        return {class: this.error ? 'error' : ''}
    }

    render() {
        let dataContent = <p>Previous close: ${this.fetchedDif}</p>
        if(this.error){
            dataContent = <p class="error">{this.error}</p>
        }
        if(this.loading){
            dataContent = <mw-spinner></mw-spinner>
        }
        return[
            <form onSubmit={this.onFetchStockPrice.bind(this)}>
                <input id="stock-symbol" ref={el => this.stockInput = el} value= {this.stockUserInput} onInput={this.onUserInput.bind(this)} ></input>
                <button type="submit" disabled={!this.stockInputValid || this.loading}>Fetch Price</button>
            </form>,
            <div id="stock-res">
                <p>Price: ${this.fetchedPrice}</p>
            </div>,
            <div id="stock-dif">
                {dataContent}
            </div>
        ];
    }
 }

 // @Listen('mwSymbolSelected', {target: 'body'})
:host {
    font-family: sans-serif;
    --main-color:  #3b013b;
    border: 2px solid var(--main-color);
    margin: 2rem;
    padding: 1rem;
    display: block;
    width: 20rem;
    max-width: 100%;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 1rem;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
}

:host(.error){
    border: 2px solid red;
}

form input {
    font: inherit;
    color: var(--main-color);
    padding: 0.1rem 0.25rem;
    display: block;
    margin-bottom: .5rem;
    margin-left: auto;
    margin-right: auto;
}

form input:focus, form button:focus {
    outline: none;
}

form button {
    font: inherit;
    background: var(--main-color);
    border: 1px solid var(--main-color);
    color: white;
    cursor: pointer;
}

form button:hover,
form button:active {
    background:#610161
}

form button:disabled {
    background: #ccc;
    color: white;
    cursor: not-allowed;
}

.error {
    color: red;
    font-weight: bold;
}


